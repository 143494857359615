import styled from 'styled-components';
import {
    alignItems,
    AlignItemsProps,
    borderRadius,
    BorderRadiusProps,
    display,
    DisplayProps,
    flex,
    flexDirection,
    FlexDirectionProps,
    FlexProps,
    flexWrap,
    FlexWrapProps,
    height,
    HeightProps,
    justifyContent,
    JustifyContentProps,
    margin,
    MarginProps,
    maxHeight,
    MaxHeightProps,
    maxWidth,
    MaxWidthProps,
    minHeight,
    MinHeightProps,
    minWidth,
    MinWidthProps,
    padding,
    PaddingProps,
    width,
    WidthProps,
} from 'styled-system';

type CustomViewProps = {
    clickable?: boolean;
};
type Props = CustomViewProps &
    MarginProps &
    WidthProps &
    MinWidthProps &
    MaxWidthProps &
    MinHeightProps &
    MaxHeightProps &
    HeightProps &
    PaddingProps &
    DisplayProps &
    FlexDirectionProps &
    AlignItemsProps &
    JustifyContentProps &
    FlexWrapProps &
    BorderRadiusProps &
    FlexProps;

const disallowedProps = ['flexDirection', 'justifyContent', 'alignItems'];
const propValidatorFn = (prop: string) => !disallowedProps.includes(prop);

export const View = styled.div.withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) =>
        propValidatorFn(prop) && defaultValidatorFn(prop),
})<Props>`
    font-family: ${(props) => props.theme.fonts.body};
    cursor: ${(props) => (props.clickable ? 'pointer' : 'inherit')};
    ${margin}
    ${width}
    ${height}
    ${padding}
    ${display}
    ${minWidth}
    ${maxWidth}
    ${minHeight}
    ${maxHeight}
    ${flexDirection}
    ${alignItems}
    ${justifyContent}
    ${flex}
    ${flexWrap}
    ${borderRadius}
`;
