import styled from 'styled-components';
import {
    AlignItemsProps,
    JustifyContentProps,
    MarginProps,
    PaddingProps,
    justifyContent,
    alignItems,
    margin,
    padding,
} from 'styled-system';

import { View } from './View';

export type RowProps = JustifyContentProps & AlignItemsProps & MarginProps & PaddingProps;
export const Row = styled(View)<RowProps>`
    display: flex;
    flex-direction: row;
    ${justifyContent}
    ${alignItems}
    ${margin}
    ${padding}
`;
